import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"flex-direction": "column",
			"align-items": "flex-start",
			"lg-width": "100%",
			"lg-align-items": "center",
			"lg-margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 0px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"padding": "16px 16px 16px 0px",
			"justify-content": "center",
			"lg-order": "1"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Bizimle İletişime Geçin"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--greyD3",
			"font": "--lead",
			"lg-text-align": "center",
			"children": "Whispering Pines Mini Evlerinde huzurlu kaçışınız için yerinizi ayırtın."
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"padding": "12px 24px 12px 24px",
			"color": "--dark",
			"text-decoration-line": "initial",
			"font": "--lead",
			"border-radius": "8px",
			"margin": "0px 16px 0px 0px",
			"transition": "background-color 0.2s ease-in-out 0s",
			"sm-margin": "0px 0px 16px 0px",
			"sm-text-align": "center",
			"background": "--color-primary",
			"hover-transition": "background-color 0.2s ease-in-out 0s",
			"hover-background": "--color-primary",
			"children": "Bize Ulaşın"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"justify-content": "flex-end",
			"lg-width": "100%",
			"align-items": "flex-start",
			"lg-margin": "0px 0px 32px 0px",
			"margin": "0px 0px 0px 0px",
			"padding": "0px 0px 0px 32px",
			"lg-padding": "0px 0px 0px 0px",
			"lg-justify-content": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://kimbachi.live/img/4.jpg",
			"object-fit": "cover",
			"width": "100%",
			"height": "100%",
			"border-radius": "24px",
			"transform": "translateY(0px)",
			"transition": "transform 0.2s ease-in-out 0s",
			"hover-transform": "translateY(-10px)",
			"min-height": "600px",
			"max-width": "480px",
			"sm-min-height": "100vw"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
		</Box>
		<Box {...override("box1")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;